import * as Collapsible from "@radix-ui/react-collapsible";
import { type FC, type ReactNode } from "react";
import classes from "./DDSCollapsible.module.css";

const DDSCollapsibleRoot: FC<{
  children?: ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?(open: boolean): void;
  disabled?: boolean;
}> = Collapsible.Root;

function DDSCollapsibleTrigger({ children, className, ...props }: Collapsible.CollapsibleTriggerProps) {
  return (
    <Collapsible.Trigger className={[classes.trigger, className].filter(Boolean).join(" ")} {...props}>
      {children}
    </Collapsible.Trigger>
  );
}

function DDSCollapsibleContent({ children, className, ...props }: Collapsible.CollapsibleContentProps) {
  return (
    <Collapsible.Content className={[classes.content, className].filter(Boolean).join(" ")} {...props}>
      <div className={classes.contentInner}>{children}</div>
    </Collapsible.Content>
  );
}

export { DDSCollapsibleRoot as DDSCollapsible, DDSCollapsibleTrigger, DDSCollapsibleContent };
