//
// Base Text component. All other components build on top of this base text element
//

import { ThemeUIStyleObject } from "../../stylingLib";
import { ColorStyles, contentColorStyleToToken } from "../theme";

export type BaseTextProps = Omit<React.HTMLAttributes<HTMLSpanElement>, "color"> &
  BaseTextConditionalProps & {
    href?: never | string;
    nowrap?: boolean;
    display?: "block" | "inline" | "inline-block" | "flex" | "inline-flex";
    textAlign?: "right" | "left" | "center" | "justify" | "initial" | "inherit";
    as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "li" | "label" | "span" | "dt" | "dd";
    htmlFor?: string;
    kind?: ColorStyles;
    textTransform?: "uppercase" | "lowercase" | "capitalize" | "none" | "onlyFirst";
  };

type BaseTextConditionalProps =
  | {
      href?: never;
      onClick?: never;
      target?: never;
    }
  | {
      as?: never;
      onClick?: React.MouseEventHandler;
      href: string;
      target?: "_blank" | "_self" | "_parent" | "_top";
    }
  | {
      as: "label";
      htmlFor: string;
    }
  | {
      as: "label";
      children: React.ReactNode;
    };

/**
 * Base Text component. All other components build on top of this base text element
 * DO NOT USE THIS COMPONENT DIRECTLY, USE ONE OF THE TYPOGRAPHY COMPONENTS INSTEAD
 * PLEASE TALK TO THE DESIGN SYSTEM TEAM BEFORE ADDING NEW PROPS OR CHANGING THE IMPLEMENTATION
 */
export const BaseText = ({
  as = "span",
  children,
  nowrap,
  display = "block",
  className,
  textAlign = "inherit",
  kind = "primary",
  textTransform = "none",
  ...props
}: BaseTextProps) => {
  const Tag = props.href ? "a" : as;

  const wrappingStyles = nowrap ? noWrapStyle : {};
  const capitalizationStyles: ThemeUIStyleObject =
    textTransform === "onlyFirst"
      ? {
          "&::first-letter": {
            textTransform: "capitalize",
          },
        }
      : { textTransform };
  return (
    <Tag
      className={`nessie-text ${className}`}
      sx={{
        ...textStyle,
        ...wrappingStyles,
        ...capitalizationStyles,
        textAlign,
        display,
        color: contentColorStyleToToken[kind === "tertiary" ? "secondary" : kind],
      }}
      {...props}
    >
      {children}
    </Tag>
  );
};

const textStyle: ThemeUIStyleObject = {
  color: "inherit",
  fontStyle: "inherit",
  fontVariant: "inherit",
  margin: 0,
  padding: 0,
  textDecorationLine: "none",

  // block level by default, but inline when nested
  display: "block",
  ">*": {
    display: "inline !important",
  },

  // default wrapping
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
};

const noWrapStyle: ThemeUIStyleObject = {
  maxWidth: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  ">.text": {
    whiteSpace: "inherit",
  },
};
