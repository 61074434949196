import * as RadioGroup from "@radix-ui/react-radio-group";
import { type ReactNode } from "react";
import { TranslatedString } from "@web-monorepo/i18next";
import classes from "./DDSRadioGroup.module.css";

type DDSRadioGroupSize = "small" | "medium";

type DDSRadioGroupRootProps = {
  value?: string;
  defaultValue?: string;
  onValueChange?: (value: string) => void;
  name?: string;
  children?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  "data-name"?: string;
};

function DDSRadioGroupRoot({ children, "data-name": dataName, required, value, ...props }: DDSRadioGroupRootProps) {
  return (
    <div className={classes.container}>
      <RadioGroup.Root className={classes.root} data-name={dataName} required={required} value={value} {...props}>
        {children}
      </RadioGroup.Root>
    </div>
  );
}

interface DDSRadioGroupItemProps extends RadioGroup.RadioGroupItemProps {
  description?: ReactNode | TranslatedString;
  "data-name"?: string;
  "aria-label"?: string;
  size?: DDSRadioGroupSize;
}

function DDSRadioGroupItem({
  children,
  description,
  "data-name": dataName,
  "aria-label": ariaLabelProp,
  size = "medium",
  ...props
}: DDSRadioGroupItemProps & { size?: DDSRadioGroupSize }) {
  const ariaLabel = ariaLabelProp || (typeof children === "string" ? children : undefined);
  const labelClassName = `${classes.label} ${size === "small" ? classes.labelSmall : classes.labelMedium}`;

  return (
    <div className={classes.itemWrapper}>
      <label className={labelClassName}>
        <RadioGroup.Item className={classes.item} data-name={dataName} aria-label={ariaLabel} {...props}>
          <RadioGroup.Indicator className={classes.indicator} />
        </RadioGroup.Item>
        {children}
      </label>
      {description && <div className={classes.description}>{description}</div>}
    </div>
  );
}

export { DDSRadioGroupRoot as DDSRadioGroup, DDSRadioGroupItem };
