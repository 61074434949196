import { FunctionComponent, PropsWithChildren } from "react";
import { DDSTooltip } from "../DDSTooltip/DDSTooltip";
import classes from "./DDSIconButton.module.css";

export const DDSIconButtonClasses = classes;

export const Sizes = ["large", "medium", "small", "xsmall"] as const;
export const Variants = [
  "primary",
  "primarySubtle",
  "secondary",
  "tertiary",
  "gray",
  "transparent",
  "media",
  "white",
] as const;

type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

type BaseProps = PropsWithChildren<{
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  ["data-name"]?: string;
  /** The size of the icon button */
  size?: (typeof Sizes)[number];
  /** The visual style variant of the button */
  variant?: (typeof Variants)[number];
  /** Required for accessibility - describes the action the button performs */
  "aria-label": string;
  /** Optional tooltip configuration */
  tooltip: {
    /** Text to display in the tooltip */
    content: string;
    /** The name will get used for automated product events for the tooltip content.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    dataName: string;
  };
}>;

type IconButtonProps = BaseProps &
  Pick<HTMLButtonProps, "type" | "autoFocus" | "tabIndex"> & {
    /** Click handler for button */
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    /** Whether the button is disabled */
    disabled?: boolean;
  };

export const DDSIconButton: FunctionComponent<IconButtonProps> = ({
  onClick,
  children,
  size = "medium",
  variant = "primary",
  disabled = false,
  type = "button",
  ["data-name"]: dataName,
  "aria-label": ariaLabel,
  tooltip,
  ...rest
}) => {
  const buttonClassName = [classes.iconButton, classes[size], disabled ? classes.disabled : null, classes[variant]]
    .filter(Boolean)
    .join(" ");

  const button = (
    <button
      data-name={dataName}
      type={type}
      className={buttonClassName}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      {...rest}
    >
      {children}
    </button>
  );

  return (
    <DDSTooltip content={tooltip.content} data-name={tooltip.dataName} align="center">
      {button}
    </DDSTooltip>
  );
};
