import React, { useLayoutEffect, useRef } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { DDSButton } from "../DDSButton/DDSButton";
import classes from "./DDSDialog.module.css";
import { type ReactNode } from "react";
import { DDSIconButton } from "../DDSIconButton/DDSIconButton";
import { IconArrowLeft, IconX } from "@web-monorepo/dds-icons";
import { getSite, logEvent, logMessage } from "@classdojo/log-client";
import { useResizeObserver } from "@web-monorepo/hooks";

interface DDSDialogProps {
  /**
   * The children may be:
   * - Both a Trigger and Content
   * - Or, if you are managing your modal state yourself, just Content
   */
  children:
    | [React.ReactElement<typeof DDSDialogTrigger>, React.ReactElement<typeof DDSDialogContent>]
    | React.ReactElement<typeof DDSDialogContent>;
  "data-name"?: string;
  "event-metadata"?: object;
  experiments?: string[];
  /**
   * Whether the dialog is open.
   */
  open?: boolean;
  /**
   * Whether the dialog is open by default.
   */
  defaultOpen?: boolean;
  /**
   * Callback function to handle the dialog open state change.
   */
  onOpenChange?(open: boolean): void;
}

const DDSDialogRoot = Dialog.Root;

const DDSDialogTrigger = Dialog.Trigger;

function DDSDialog({ children, ...props }: DDSDialogProps) {
  const { "data-name": dataName, "event-metadata": metadata, experiments, open } = props;

  React.useEffect(() => {
    if (!dataName) {
      logMessage("WARN", `${getSite()}.dialog.missingDataName`);
      return;
    }

    logEvent({
      eventName: `${getSite()}.dialog.${dataName}.open`,
      automatedEvent: true,
      metadata,
      experiments,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savedElement = useRef<(typeof document)["activeElement"]>();

  useLayoutEffect(() => {
    if (typeof open !== "boolean") return;
    if (open) {
      savedElement.current = document.activeElement;
    } else {
      savedElement.current &&
        "focus" in savedElement.current &&
        typeof savedElement.current.focus === "function" &&
        savedElement.current.focus();
    }
  }, [open]);

  return (
    <DDSDialogRoot open={open} {...props}>
      {children}
    </DDSDialogRoot>
  );
}

type BaseDialogContentProps = Omit<Dialog.DialogContentProps, "onClose"> & {
  children: [React.ReactElement<typeof DDSDialogHeader>, ...React.ReactNode[]];
  /**
   * When true, the dialog will maintain its initial calculated height
   * even when content size changes, preventing layout shifts
   */
  maintainInitialHeight?: boolean;
};

type RequireExplicitCloseProps = BaseDialogContentProps & {
  /**
   * Disables closing the dialog on background click, using the escape key, or clicking outside the dialog.
   * Best for modals where forms are being filled out and you don't want to lose the data on an erroneous outside click.
   */
  requireExplicitClose: true;
  onClose?: never;
};

type OnCloseProps = BaseDialogContentProps & {
  requireExplicitClose?: never;
  /**
   * If you're managing your modal state yourself, you can pass in an onClose function to handle the close event for clicking outside the dialog
   */
  onClose: () => void;
};

type DefaultProps = BaseDialogContentProps & {
  requireExplicitClose?: never;
  onClose?: never;
};

type DDSDialogContentProps = RequireExplicitCloseProps | OnCloseProps | DefaultProps;

function DDSDialogContent({
  children,
  requireExplicitClose,
  maintainInitialHeight,
  onClose,
  ...rest
}: DDSDialogContentProps) {
  // Runtime check for header
  if (!React.isValidElement(children[0]) || children[0].type !== DDSDialogHeader) {
    throw new TypeError(
      "DDSDialogContent requires DDSDialogHeader as its first child. If you don't want to show the header title, you may pass `visuallyHideTitle` to the header.",
    );
  }

  const [initialHeight, setInitialHeight] = React.useState<number | null>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // Add a small delay to ensure content is rendered before calculating height
    const timer = setTimeout(() => {
      if (maintainInitialHeight && contentRef.current && !initialHeight) {
        const height = contentRef.current.offsetHeight;
        if (height > 0) {
          setInitialHeight(height);
        }
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [maintainInitialHeight, initialHeight, children]);

  const handleClose = (e: { preventDefault: () => void }) => {
    if (onClose) {
      onClose();
    }
    if (requireExplicitClose) {
      e.preventDefault();
    }
  };

  return (
    <Dialog.Overlay className={classes.overlay}>
      <Dialog.Content
        className={classes.container}
        aria-describedby={undefined}
        aria-labelledby="dialog-title"
        onPointerDownOutside={handleClose}
        onInteractOutside={handleClose}
        onEscapeKeyDown={handleClose}
        onFocusCapture={(e) => {
          // This prevents header tooltips from being auto-opened when the dialog is opened.
          e.stopPropagation();
        }}
        {...rest}
      >
        <div
          ref={contentRef}
          className={classes.content}
          style={maintainInitialHeight && initialHeight ? { minHeight: `${initialHeight}px` } : undefined}
        >
          {children}
        </div>
      </Dialog.Content>
    </Dialog.Overlay>
  );
}

export type DDSDialogHeaderProps = {
  title: string;
  /**
   * Whether to hide the title visually. Keeps it in the DOM for accessibility.
   */
  visuallyHideTitle?: boolean;
  className?: string;
  onBack?: () => void;
  removeCloseButton?: boolean;
  /**
   * If you're managing your modal state yourself, you can pass in an onClose function to handle the close event
   */
  onClose?: () => void;
  "data-name"?: string;
  "event-metadata"?: object;
  experiments?: string[];
};
function DDSDialogHeader({
  title,
  className,
  onBack,
  onClose,
  removeCloseButton,
  visuallyHideTitle,
  "data-name": dataName,
  "event-metadata": metadata,
  experiments,
  ...props
}: DDSDialogHeaderProps) {
  const handleOnClose = () => {
    if (onClose) {
      logEventClose();
      onClose();
    }
  };

  const logEventClose = () => {
    if (!dataName) return;

    logEvent({
      eventName: `${getSite()}.dialog.${dataName}.close`,
      automatedEvent: true,
      metadata,
      experiments,
    });
  };

  return (
    <div
      className={[
        classes.header,
        !onBack && visuallyHideTitle && !removeCloseButton ? classes.headerRightAligned : classes.headerSpaceBetween,
        className,
      ]
        .filter(Boolean)
        .join(" ")}
      {...props}
    >
      {onBack && (
        <div className={classes.headerLeft}>
          <DDSIconButton
            variant="gray"
            size="small"
            onClick={onBack}
            data-name="dialog.back"
            tooltip={{ content: "Back", dataName: "dialog.backTooltip" }}
            aria-label={autoTranslate("Go back")}
          >
            <IconArrowLeft size="s" />
          </DDSIconButton>
        </div>
      )}
      {visuallyHideTitle ? (
        <VisuallyHidden.Root>
          <Dialog.Title className={classes.title} id="dialog-title">
            {title}
          </Dialog.Title>
        </VisuallyHidden.Root>
      ) : (
        <Dialog.Title className={classes.title} id="dialog-title">
          {title}
        </Dialog.Title>
      )}
      {removeCloseButton ? (
        <div className={classes.noClose}></div>
      ) : onClose ? (
        <DDSIconButton
          variant="gray"
          size="small"
          onClick={handleOnClose}
          data-name="dialog.close"
          tooltip={{ content: "Close", dataName: "dialog.closeTooltip" }}
          aria-label={autoTranslate("Close")}
        >
          <IconX size="s" />
        </DDSIconButton>
      ) : (
        <Dialog.Close aria-label={autoTranslate("Close")} asChild>
          <DDSIconButton
            variant="gray"
            size="small"
            onClick={() => {}}
            data-name="dialog.close"
            tooltip={{ content: "Close", dataName: "dialog.closeTooltip" }}
            aria-label={autoTranslate("Close")}
          >
            <IconX size="s" />
          </DDSIconButton>
        </Dialog.Close>
      )}
    </div>
  );
}

function DDSDialogBody({ children, className }: { children: ReactNode; className?: string }) {
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = React.useState(false);

  function updateScrollable() {
    if (bodyRef.current) {
      const hasOverflow = bodyRef.current.scrollHeight > bodyRef.current.clientHeight;
      setIsScrollable(hasOverflow);
    }
  }

  useResizeObserver({
    ref: bodyRef,
    onResize: updateScrollable,
  });

  useLayoutEffect(updateScrollable, [bodyRef]);

  return (
    <div
      ref={bodyRef}
      className={[classes.body, className].filter(Boolean).join(" ")}
      tabIndex={isScrollable ? 0 : undefined}
    >
      {children}
    </div>
  );
}

// TODO: (nice to have) show top shadow on footer if content is scrollable in the modal.
// Will require a resize observer to check if the content is scrollable.
function DDSDialogFooter({ children, className }: { children: ReactNode; className?: string }) {
  return <div className={[classes.footer, className].filter(Boolean).join(" ")}>{children}</div>;
}

function DDSDialogCloseButton({ children, "data-name": dataName }: { children: ReactNode; "data-name": string }) {
  return (
    <Dialog.Close asChild>
      <DDSButton data-name={dataName} variant="secondary" size="medium">
        {children}
      </DDSButton>
    </Dialog.Close>
  );
}

export {
  DDSDialog,
  DDSDialogTrigger,
  DDSDialogContent,
  DDSDialogHeader,
  DDSDialogFooter,
  DDSDialogCloseButton,
  DDSDialogBody,
};
