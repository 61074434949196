import type { FunctionComponent, PropsWithChildren } from "react";
import * as Classes from "./DDSMinutiaeText.module.css";

export type DDSMinutiaeTextProps = {
  as?: "span";
  className?: string;
};

export const DDSMinutiaeText: FunctionComponent<PropsWithChildren<DDSMinutiaeTextProps>> = ({
  as: tagName = "span",
  className,
  children,
}) => {
  const Tag = tagName;
  const classNames = [className, Classes["minutiae"]].filter((c) => !!c);
  return <Tag className={classNames.join(" ")}>{children}</Tag>;
};
