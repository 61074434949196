import { spaces, radius, breakpoints, shadows, fontFamily, fontWeights, zIndexes, stackingOrder } from "./designTokens";
import { ddsColors, alphaCodes } from "./ddsColors";
import { nessieColors } from "./nessieColors";

/**
 * DDS Dark Color Palette
 * https://www.figma.com/file/erhFTAWNMugPVrJGCAWsse/%E2%9D%96-Dojo-Design-System-%E2%80%93-DDS?type=design&node-id=1622-2&mode=design&t=lek7htRu8RCBQ5Sp-0
 */

export const ddsDarkContentColors = {
  dt_content_primary: ddsColors.white,
  dt_content_secondary: ddsColors.cloud_40,
  dt_content_tertiary: ddsColors.cloud_50,
  dt_content_light: ddsColors.white,
  dt_content_disabled: ddsColors.cloud_70,
  dt_content_accent: ddsColors.grape_50, // neutral
  dt_content_accent_dark: ddsColors.grape_50,
  dt_content_success: ddsColors.frog_40,
  dt_content_warning: ddsColors.sun_20,
  dt_content_danger: ddsColors.apple_50,
  dt_content_plus: ddsColors.grape_50,
};

export const ddsDarkBackgroundColors = {
  dt_background_primary: ddsColors.cloud_90,
  dt_background_secondary: ddsColors.cloud_80,
  dt_background_tertiary: ddsColors.cloud_70,
  dt_background_disabled: `${ddsColors.cloud_60}${alphaCodes.alpha_20}`, // rgba(170, 176, 216, 0.2)
  dt_background_media: `${ddsColors.cloud_60}${alphaCodes.alpha_60}`, // rgba(93, 93, 143, 0.6)
  dt_background_skrim: `${ddsColors.cloud_90}${alphaCodes.alpha_80}`, // rgba(26, 25, 45, 0.8): use under lightbox
  dt_background_accent: ddsColors.grape_80, // neutral
  dt_background_success: ddsColors.frog_80,
  dt_background_warning: ddsColors.sun_80,
  dt_background_danger: ddsColors.apple_80,
};

export const ddsDarkBorderColors = {
  dt_border_functional: `${ddsColors.cloud_50}${alphaCodes.alpha_70}`, // rgba(187, 192, 255, 0.7)
  dt_border_stylistic: `${ddsColors.cloud_30}${alphaCodes.alpha_40}`, // rgba(166, 164, 255, 0.4): dividers
  dt_border_divider: `${ddsColors.cloud_30}${alphaCodes.alpha_40}`, // rgba(166, 164, 255, 0.4): dividers
  dt_border_transparent: ddsColors.cloud_50,
  dt_border_disabled: `${ddsColors.cloud_90}${alphaCodes.alpha_25}`, // rgba(245, 235, 255, 0.45)
  dt_border_active: ddsColors.grape_50,
};

export const ddsDarkBrandColors = {
  dt_brand_classDojo: ddsColors.grape_60,
  dt_brand_plus: `linear-gradient(90deg, ${ddsColors.grape_50} 0%, ${ddsColors.grape_60} 35%, ${ddsColors.gradiant_blue} 100%)`,
  dt_brand_tutor: "#2054EE", // not in dds
};

export const contentColorStyleToToken: Record<ColorStyles, DDSContentColorKinds> = {
  primary: "dt_content_primary",
  secondary: "dt_content_secondary",
  tertiary: "dt_content_tertiary",
  light: "dt_content_light",
  disabled: "dt_content_disabled",
  accent: "dt_content_accent",
  success: "dt_content_success",
  warning: "dt_content_warning",
  danger: "dt_content_danger",
  plus: "dt_content_plus",
} as const;

export type ColorStyles =
  | "primary"
  | "secondary"
  | "tertiary"
  | "light"
  | "disabled"
  | "accent"
  | "success"
  | "warning"
  | "plus"
  | "danger";

export type DDSContentColorKinds = keyof typeof ddsDarkContentColors;

//
// Primary theme object required by our ThemeProvider
//
export const theme = {
  fontFamily,
  fontWeights,
  space: spaces,
  radii: radius,
  shadows,
  breakpoints,
  zIndexes,
  stackingOrder,
  colors: {
    // transparent: "transparent",
    // inherit: "inherit",
    // white: "#ffffff",
    // black: "#000000",
    ...nessieColors, // NOTE Puri: Delete this after the migration
    ...ddsDarkContentColors,
    ...ddsDarkBackgroundColors,
    ...ddsDarkBorderColors,
    ...ddsDarkBrandColors,
  },
  borders: {
    none: "none",
    unset: "unset",
    dt_card: `2px solid ${ddsDarkBorderColors.dt_border_stylistic}`,
    dt_functional: `2px solid ${ddsDarkBorderColors.dt_border_functional}`,
    dt_active: `2px solid ${ddsDarkBorderColors.dt_border_active}`,
    dt_divider: `2px solid ${ddsDarkBorderColors.dt_border_divider}`,
    dt_disabled: `2px solid ${ddsDarkBorderColors.dt_border_disabled}`,
  },
};
