import * as React from "react";
import { LabelText } from "../../nessie/components/typography/labelText";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { TranslatedString } from "../../pods/i18n/translate";
import LoadingMojo from "../misc/LoadingMojo";
import CloseButton from "./CloseButton";
import Footer from "./Footer";
import ScrollableModal from "./Scrollable";
import styles from "./Styles";

const SMALL_SIZE = "51.2rem";

/**
 * Modal with our standard design and possible actions.
 */
type StandardModalProps = {
  title?: TranslatedString;
  label?: TranslatedString;
  small?: boolean;
  width?: React.CSSProperties["width"];
  contentStyle?: ThemeUIStyleObject;
  children?: React.ReactNode;
  loading?: boolean;

  header?: React.ComponentPropsWithoutRef<typeof ScrollableModal>["header"];
  headerStyle?: ThemeUIStyleObject;
  headerRightSideElement?: React.ReactNode;
  headerHeight?: number;
  footerHeight?: number;

  footer?: React.ComponentPropsWithoutRef<typeof ScrollableModal>["footer"];

  requestHideOnOverlayClick?: React.ComponentPropsWithoutRef<typeof ScrollableModal>["requestHideOnOverlayClick"];
  onRequestHide?: React.MouseEventHandler;
  spaceAroundButtons?: boolean;
} & Pick<
  React.ComponentPropsWithoutRef<typeof Footer>,
  "primaryButton" | "secondaryButton" | "tertiaryButton" | "tooltip"
> &
  Omit<
    React.ComponentPropsWithoutRef<typeof ScrollableModal>,
    "header" | "footer" | "footerHeight" | "style" | "requestHideOnOverlayClick" | "label"
  >;

// eslint-disable-next-line complexity
const StandardModal = ({
  title,
  label,
  small,
  width,
  contentStyle,
  children,
  loading,
  header,
  headerStyle,
  headerRightSideElement,
  footer,
  primaryButton,
  secondaryButton,
  tertiaryButton,
  tooltip,
  requestHideOnOverlayClick,
  onRequestHide,
  spaceAroundButtons,
  "data-name": dataName = "standard_modal",
  headerHeight = 68,
  footerHeight = 77,
  ...modalProps
}: StandardModalProps): JSX.Element => {
  const modalStyle: ThemeUIStyleObject = {};

  if (small) {
    modalStyle.width = SMALL_SIZE;
  }

  if (width) {
    modalStyle.width = width;
  }

  const titleContent = (
    <LabelText as="h2" sx={{ ...styles.modalHeaderTitle, flex: 1, paddingRight: "dt_l" }}>
      {title}
    </LabelText>
  );
  let headerContent;
  if (!header) {
    if (headerRightSideElement) {
      headerContent = (
        <div sx={{ display: "flex" }}>
          {titleContent}
          <div sx={styles.headerRightSideElement}>{headerRightSideElement}</div>
        </div>
      );
    } else {
      headerContent = titleContent;
    }
  }
  const headerElement = header || (
    <div sx={{ ...styles.modalHeader, ...headerStyle }}>
      {headerContent}
      {onRequestHide && !headerRightSideElement && (
        <CloseButton data-name={`${dataName}:close_button`} close={onRequestHide} />
      )}
    </div>
  );

  const content = (
    <div sx={{ ...styles.content, ...contentStyle }}>
      {loading ? (
        <div sx={styles.loadingContainer}>
          <LoadingMojo />
        </div>
      ) : (
        children
      )}
      {tooltip && <div sx={styles.tooltipSpacer} />}
    </div>
  );

  const hasFooter = footer || primaryButton || secondaryButton || tertiaryButton || footer;
  const footerElement =
    hasFooter &&
    !loading &&
    (footer || (
      <Footer
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        tertiaryButton={tertiaryButton}
        tooltip={tooltip}
        spaceAroundButtons={spaceAroundButtons}
      />
    ));

  return (
    <ScrollableModal
      data-name={dataName}
      requestHideOnOverlayClick={requestHideOnOverlayClick == null ? !hasFooter : requestHideOnOverlayClick}
      onRequestHide={onRequestHide}
      header={headerElement}
      headerHeight={headerHeight}
      footer={footerElement}
      footerHeight={footerHeight}
      {...modalProps}
      sx={modalStyle}
      label={label || title}
    >
      {content}
    </ScrollableModal>
  );
};

export default StandardModal;
