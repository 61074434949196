import sortBy from "lodash/sortBy";
import { memo } from "react";
import { LabelText, Space } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { fullName } from "../../utils/name";
import { translate } from "../../utils/translate";
import ProfilePicture from "../misc/ProfilePicture";
import TooltipTrigger from "../overlay/TooltipTrigger";
import { format, isSame, parse, startOf } from "@web-monorepo/dates";
import { fromNow, fullDateTimestampString } from "../../utils/time";

type PostHeaderProps = {
  primaryText: string;
  secondaryText?: string;
  postTime: string;
  posterId?: string;
  avatarURL?: string;
  pending?: boolean;
  postTags?: Array<
    {
      _id: string;
    } & Parameters<typeof fullName>[0]
  >;
};

// eslint-disable-next-line react-refresh/only-export-components
const PostHeader = ({
  posterId,
  avatarURL,
  primaryText,
  pending,
  postTags,
  secondaryText,
  postTime,
}: PostHeaderProps) => {
  const renderPendingBanner = () => {
    if (!pending) return null;
    return <div sx={PENDING_BANNER_STYLE}>{translate("dojo.common:post_flow.pending_post_banner")}</div>;
  };

  const renderStudentNameTooltip = () => {
    if (!postTags || postTags.length <= 1) {
      return null;
    }

    return sortBy(postTags, "firstName").map((s) => <div key={s._id}>{fullName(s)}</div>);
  };

  const renderSecondaryText = () => {
    const secondary = secondaryText ? <LabelText kind="tertiary">{secondaryText}</LabelText> : null;

    const view = renderStudentNameTooltip();

    if (!view) {
      return secondary;
    } else {
      return (
        <TooltipTrigger
          tooltipContent={view}
          tooltipPosition="top"
          tooltipCaretOffset="50%"
          tooltipGutter={"dt_s"}
          tooltipTextSize="1.4rem"
        >
          {secondary}
        </TooltipTrigger>
      );
    }
  };

  const localizedPostTime = parse(postTime);
  const renderTime = () => {
    const withinToday = isSame(new Date(), localizedPostTime, { mode: "day" });
    const withinYear = isSame(new Date(), localizedPostTime, { mode: "year" });
    // withinToday = "today", withinYear = "Dec 14", !withinYear = "Dec 14, 2015"
    if (withinToday) {
      // return translate("dojo.common:time_interval.today");
      return fromNow(startOf(localizedPostTime, "minute"));
    } else if (withinYear) {
      return format(localizedPostTime, { month: "short", day: "numeric" });
    } else {
      return format(localizedPostTime, "ll");
    }
  };

  return (
    <div data-name="storyPostHeader" sx={{ position: "relative" }}>
      {renderPendingBanner()}
      <div
        sx={{
          paddingLeft: "dt_m",
          paddingRight: "dt_m",
          display: "flex",
          flex: 1,
          flexDirection: "row",
          position: "relative",
        }}
      >
        <ProfilePicture size="4.4rem" src={avatarURL} colorKey={posterId} />
        <div
          sx={{
            flexGrow: 1,
            display: "flex",
            flex: 1,
            flexDirection: "column",
            marginLeft: "dt_s",
            position: "relative",
          }}
        >
          <LabelText as={"h3"}>{primaryText}</LabelText>
          {renderSecondaryText()}
        </div>
        <LabelText kind="tertiary" title={fullDateTimestampString(localizedPostTime)}>
          {renderTime()}
        </LabelText>
      </div>
      <Space size="m" />
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default memo(PostHeader);

const PENDING_BANNER_STYLE: ThemeUIStyleObject = {
  position: "relative",
  width: "100%",
  backgroundColor: "dt_background_secondary",
  paddingY: "dt_s",
  paddingX: "dt_m",
  zIndex: 1,
};
