import { FunctionComponent, PropsWithChildren } from "react";
import { generatePath } from "react-router-dom";
import classes from "./button.module.css";
import React from "react";

export const Sizes = ["large", "medium", "small"] as const;
export const Variants = [
  "primary",
  "primarySubtle",
  "secondary",
  "tertiary",
  "destructive",
  "destructiveTertiary",
  "ghost",
  "unstyled",
  "menuItem",
] as const;
export const Widths = ["fit", "fill"] as const;

type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

type BaseProps = PropsWithChildren<{
  ["data-name"]: string;
  size?: (typeof Sizes)[number];
  variant?: (typeof Variants)[number];
  width?: (typeof Widths)[number];
  experiments?: string[];
}>;

type ButtonProps = BaseProps &
  Pick<HTMLButtonProps, "type" | "autoFocus" | "tabIndex"> & {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    className?: string;
  };

export const ButtonClasses = classes;
export const VariantClasses = Object.fromEntries(Variants.map((v) => [v, classes[v]]));

export const DDSButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick,
      children,
      size = "medium",
      variant = "primary",
      disabled = false,
      type = "button",
      ["data-name"]: dataName,
      width,
      experiments,
      ...rest
    },
    ref,
  ) => {
    const className =
      variant === "unstyled"
        ? classes.unstyled
        : [classes.button, classes[size], classes[variant], width ? classes[width] : null].filter(Boolean).join(" ");

    return (
      <button
        data-name={dataName}
        data-experiments={experiments}
        type={type}
        className={className}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    );
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Params = Record<string, any>;

type LinkProps<T extends string> = BaseProps &
  (
    | {
        to: T;
        search?: string;
        params?: Params;
        target?: string;
      }
    | { href: string; target?: string }
  );

// Helper type to extract the props of a React component
type ComponentProps<T> = T extends React.ComponentType<infer P> ? P : never;

export function LinkButtonFactory<
  T extends FunctionComponent<
    PropsWithChildren<{
      to: string;
      search?: string;
      params?: Params;
      target?: string;
    }>
  >,
>(LinkType: T): FunctionComponent<LinkProps<ComponentProps<T>["to"]>> {
  // I haven't figured out a way to
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Link = LinkType as any;
  return ({
    children,
    size = "medium",
    variant = "primary",
    width = "fit",
    ["data-name"]: dataName,
    target,
    ...rest
  }) => {
    const className = [classes.button, classes[size], classes[variant], classes[width]].join(" ");

    if ("to" in rest && rest.to) {
      const path = generatePath(
        rest.to,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        "params" in rest ? rest.params : ({} as any),
      );

      return (
        <Link className={className} to={path} search={rest.search} data-name={dataName} experiments={rest.experiments}>
          {children}
        </Link>
      );
    } else {
      return (
        <a
          href={("href" in rest && rest.href) || undefined}
          className={className}
          data-name={dataName}
          data-experiments={rest.experiments}
          target={target}
        >
          {children}
        </a>
      );
    }
  };
}
