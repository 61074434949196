import * as logClient from "@classdojo/log-client";
import { useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import useWatch from "./useWatch";
import { ProductAreaContext } from "../pods/errorHandling/productArea";
import logEvent from "../utils/logEvent";
import { sanitizeQueryString } from "../utils/queryStrings";

// Put this inside the top level component within your project's router
// (probably called ApplicationContainer)

export type LoggerContextOptions = {
  entityType?: "teacher" | "parent" | "studentUser" | "preSignUp" | undefined;
  fetchedFeatureSwitches: Record<string, string> | undefined;
  prefix: "teach" | "student" | "home" | "marketplace";
  experimentsObject?: { [k: string]: string[] };
};

export function useLoggerContext({
  entityType,
  fetchedFeatureSwitches,
  prefix,
  experimentsObject,
}: LoggerContextOptions) {
  // Technically we won't actually hear about the product area changes because they're now refs, but...
  // We only change it right now when the route changes, which this component is already re-rendering on anyway.
  // We also forcibly set productArea in the logClient in the productArea setting components,
  // in case there are timing differences.
  const productAreaContext = useContext(ProductAreaContext);

  // route, in the context of our event system includes the whole href:
  const route = getRoute(window.location.href, useParams());
  const location = useLocation();
  const path = sanitizeQueryString(location?.pathname);
  const routeName = getRoute(location.pathname, useParams());

  useWatch([path, route, routeName], ([path, route, routeName], previous) => {
    logClient.setPath(path);
    logClient.setRoute(route);
    if (!previous) return;

    const [fromPath, fromRoute] = previous;
    const experiments = experimentsObject?.[routeName];
    logEvent({
      eventName: `${prefix}.routerNavigation`,
      automatedEvent: true,
      metadata: { path, fromPath, route, fromRoute },
      ...(experiments && { experiments }),
    });
  });

  useWatch(productAreaContext?.current, (productArea) => productArea && logClient.setProductArea(productArea));
  useWatch(entityType, (entityType) => entityType && logClient.setEntityType(entityType));
  useWatch(
    fetchedFeatureSwitches,
    (featureSwitches) => featureSwitches && logClient.setFeatureSwitches(featureSwitches),
    { deep: true },
  );
}

export const getRoute = (route: string, params: Record<string, string | undefined>) => {
  Object.keys(params || {}).forEach((key) => {
    const val = params[key];
    if (val) {
      route = route.replace(val, `:${key}`);
    }
  });
  return sanitizeQueryString(route);
};
