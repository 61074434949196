import * as React from "react";
import { NessieThemeColors } from "../../nessie/components/theme";
import { NessieSpaceSizes } from "../../nessie/components/designTokens";
import { VisuallyHidden } from "../misc/VisuallyHidden";
import WithClickOutside from "../misc/WithClickOutside";
import { AbsolutePositioner } from "../positioning";
import calculatePosition from "./CalculatePosition";
import Tooltip, { tooltipMaxWidth as defaultTooltipMaxWidth } from "./Tooltip";
/**
 * Component for wrapping an element that should display our custom tooltip
 * when hovering.
 */

type TooltipTriggerProps = {
  hide?: boolean;
  isOpen?: boolean;
  block?: boolean;
  accessibleContent?: boolean;
  role?: string;
  tabIndex?: number;
  children?: React.ReactNode;
  "aria-label"?: string;
} & TooltipAtPositionProps &
  Omit<JSX.IntrinsicElements["div"], "style" | "children" | "onMouseEnter" | "onMouseLeave" | "onClick">;

const TooltipTrigger = ({
  tooltipContent,
  tooltipPosition = "top",
  tooltipCaret = true,
  tooltipOffset,
  tooltipCaretOffset,
  tooltipGutter,
  tooltipMaxWidth = defaultTooltipMaxWidth,
  tooltipAlign,
  tooltipCaretSize,
  tooltipTextColor,
  tooltipTextSize,
  tooltipBackgroundColor,
  tooltipPadding,
  isOpen,
  block,
  accessibleContent,
  children,
  role = "tooltip",
  "aria-label": ariaLabel,
  tabIndex,
  hide,
  ...props
}: TooltipTriggerProps): JSX.Element | null => {
  const [isHovered, setIsHovered] = React.useState(false);

  if (hide && children) return <>{children}</>;
  return (
    <WithClickOutside onClickOutside={() => setIsHovered(false)} wrapper={block ? "div" : "span"}>
      {accessibleContent && <VisuallyHidden>{tooltipContent}</VisuallyHidden>}
      <div
        {...props}
        sx={{
          position: "relative",
          display: block ? "block" : "inline-block",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setIsHovered(true)}
        onFocus={() => setIsHovered(true)}
        onBlur={() => setIsHovered(false)}
        role={role}
        aria-label={ariaLabel}
        tabIndex={tabIndex}
      >
        {children}
        {(isOpen || (isHovered && isOpen !== false)) && (
          <TooltipAtPosition
            tooltipContent={tooltipContent}
            tooltipPosition={tooltipPosition}
            tooltipCaret={tooltipCaret}
            tooltipOffset={tooltipOffset}
            tooltipCaretOffset={tooltipCaretOffset}
            tooltipGutter={tooltipGutter}
            tooltipMaxWidth={tooltipMaxWidth}
            tooltipAlign={tooltipAlign}
            tooltipCaretSize={tooltipCaretSize}
            tooltipTextColor={tooltipTextColor}
            tooltipTextSize={tooltipTextSize}
            tooltipBackgroundColor={tooltipBackgroundColor}
            tooltipPadding={tooltipPadding}
          />
        )}
      </div>
    </WithClickOutside>
  );
};

type TooltipAtPositionProps = {
  tooltipContent: React.ReactNode;
  tooltipPosition?: "top" | "bottom" | "left" | "right";
  tooltipCaret?: boolean;
  tooltipOffset?: string;
  tooltipCaretOffset?: string;
  tooltipGutter?: NessieSpaceSizes;
  tooltipMaxWidth?: string;
  tooltipAlign?: "left" | "center" | "right";
  tooltipCaretSize?: string;
  tooltipTextColor?: NessieThemeColors;
  tooltipTextSize?: string;
  tooltipBackgroundColor?: NessieThemeColors;
  tooltipPadding?: NessieSpaceSizes;
};

const TooltipAtPosition = ({
  tooltipContent,
  tooltipPosition,
  tooltipCaret,
  tooltipOffset,
  tooltipCaretOffset,
  tooltipGutter,
  tooltipMaxWidth,
  tooltipAlign = "left",
  tooltipCaretSize,
  tooltipTextColor,
  tooltipTextSize,
  tooltipBackgroundColor,
  tooltipPadding,
}: TooltipAtPositionProps): JSX.Element => {
  const { caret, positionerProps } = calculatePosition({
    position: tooltipPosition,
    caret: tooltipCaret,
    offset: tooltipOffset,
    caretOffset: tooltipCaretOffset,
    gutter: tooltipGutter,
    width: tooltipMaxWidth,
    align: tooltipAlign,
  });

  return (
    <AbsolutePositioner {...positionerProps}>
      <Tooltip
        caretOffset={tooltipCaretOffset}
        caretSize={tooltipCaretSize}
        textColor={tooltipTextColor}
        textSize={tooltipTextSize}
        backgroundColor={tooltipBackgroundColor}
        caret={caret}
        sx={{ maxWidth: tooltipMaxWidth, padding: tooltipPadding }}
      >
        {tooltipContent}
      </Tooltip>
    </AbsolutePositioner>
  );
};

export default TooltipTrigger;
