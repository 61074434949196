import * as logClient from "@classdojo/log-client";
import {
  createContext,
  useContext,
  useEffect,
  useRef,
  createRef,
  MutableRefObject,
  Component,
  PropsWithChildren,
} from "react";

//---------------------------------------------------------------
// This list of Product Areas is shared with logs.classdojo.com,
// so please make sure to update it in both places if there are
// any changes needed
//
// - logs.classdojo.com/server/utils/teamProductAreas.js
// - web/src/pods/error/productArea.ts
//---------------------------------------------------------------
export const ProductAreas = {
  assistant: "assistant",
  attendance: "attendance",
  avatarSets: "avatarSets",
  beyond: "beyond",
  calendarEvent: "calendarEvent",
  classIslands: "classIslands",
  classroom: "classroom",
  communities: "communities",
  connection: "connection",
  dataExport: "dataExport",
  dojoIslandsAccess: "dojoIslandsAccess",
  dojoIslandsOnline: "dojoIslandsOnline",
  dojoIslandsReports: "dojoIslandsReports",
  feature_switch: "feature_switch",
  fileUpload: "fileUpload",
  homeawards: "homeawards",
  homeActivity: "homeActivity",
  homeConsent: "homeConsent",
  homeIslands: "homeIslands",
  infra: "infra",
  ideas: "ideas",
  launchpad: "launchpad",
  locales: "locales",
  message: "message",
  moments: "moments",
  monsterCustomizer: "monsterCustomizer",
  notifications: "notifications",
  pdf: "pdf",
  playerInventory: "playerInventory",
  points: "points",
  portfolio: "portfolio",
  purchase: "purchase",
  push: "push",
  school: "school",
  academicYearRecap: "academicYearRecap",
  session: "session",
  shared: "shared",
  story: "story",
  studentSettings: "studentSettings",
  studentUser: "studentUser",
  toolkit: "toolkit",
  userPhotos: "userPhotos",

  unknown: "unknown",
} as const;

//
// Global state  Approach
//
const defaultContextValue = {
  productArea: ProductAreas.unknown,
};

type ProductAreaKeys = keyof typeof ProductAreas;
export type ProductAreaValues = (typeof ProductAreas)[ProductAreaKeys];

type ProductAreaRefValue = ProductAreaValues | null;
type ProductAreaRef = MutableRefObject<ProductAreaRefValue>;

/* eslint-disable-next-line react-refresh/only-export-components */
export const ProductAreaContext = createContext<ProductAreaRef>(createRef<ProductAreaRefValue>());

type ProductAreaContextProviderProps = {
  children: React.ReactNode;
};

export function ProductAreaContextProvider({ children }: ProductAreaContextProviderProps): JSX.Element {
  const productArea = useRef<ProductAreaValues>(defaultContextValue.productArea);

  return <ProductAreaContext.Provider value={productArea}>{children}</ProductAreaContext.Provider>;
}

const useProductAreaContext = () => {
  return useContext(ProductAreaContext);
};

/**
 * Wraps a Component and sets the app current product area when the component is rendered.
 * This is intended to be used for wrapping route components as it will set the product area in
 * the AppTopLevelErrorBoundary.
 * @param {JSXElement} Component
 * @param {string} productArea - one of the ProductAreas
 */
// eslint-disable-next-line react-refresh/only-export-components
export const withTopLevelProductArea = (Component: React.ComponentType, productArea: ProductAreaValues) => {
  return (props: Record<string, unknown>): JSX.Element => {
    const productAreaRef = useProductAreaContext();

    useEffect(() => {
      if (productAreaRef) {
        productAreaRef.current = productArea;
        logClient.setProductArea(productArea);
      }
    }, [productAreaRef]);

    return <Component {...props} />;
  };
};

export const WithTopLevelProductArea: React.FC<PropsWithChildren<{ productArea: ProductAreaValues }>> = ({
  productArea,
  children,
}: PropsWithChildren<{ productArea: ProductAreaValues }>) => {
  const productAreaRef = useProductAreaContext();

  useEffect(() => {
    if (productAreaRef) {
      productAreaRef.current = productArea;
      logClient.setProductArea(productArea);
    }
  }, [productAreaRef, productArea]);

  return <>{children}</>;
};

/**
 * This HoC should be used whenever we want to override the top level product
 * area in a component subtree. It will set the product area in context and also
 * annotate all errors that happen within the subtree with the specified product area.
 * This relies on the AppTopLevelErrorBoundary to actually log the error.
 * @param {JSXElement} Component
 * @param {string} productArea - one of the ProductAreas
 */
// eslint-disable-next-line react-refresh/only-export-components
export const withProductArea = <P extends object>(
  Component: React.ComponentType<P>,
  productArea: ProductAreaValues,
) => {
  return (props: P): JSX.Element => {
    return (
      <ProductAreaErrorBoundary productArea={productArea}>
        <Component {...props} />
      </ProductAreaErrorBoundary>
    );
  };
};

type ProductAreaErrorBoundaryProps = {
  productArea: ProductAreaValues;
  children: React.ReactNode;
};

class ProductAreaErrorBoundary extends Component<ProductAreaErrorBoundaryProps> {
  constructor(props: ProductAreaErrorBoundaryProps) {
    super(props);
    this.productAreaRef = createRef<ProductAreaValues>();
  }

  render() {
    const { productArea, children } = this.props;
    this.productAreaRef.current = productArea;
    logClient.setProductArea(productArea);
    return <ProductAreaContext.Provider value={this.productAreaRef}>{children}</ProductAreaContext.Provider>;
  }

  productAreaRef: ProductAreaRef;

  componentDidCatch(e: Error & { productArea?: ProductAreaValues }) {
    if (!e.productArea && this.productAreaRef.current) {
      e.productArea = this.productAreaRef.current;
    }
    throw e;
  }
}
