import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { DDSButton } from "../DDSButton/DDSButton";
import { DDSHeadlineText } from "../DDSHeadlineText/DDSHeadlineText";
import { DDSBodyText } from "../DDSBodyText/DDSBodyText";
import classes from "./DDSAlertDialog.module.css";
import { type ReactNode, useRef, useLayoutEffect } from "react";
import React from "react";
import { getSite, logEvent, logMessage } from "@classdojo/log-client";

interface DDSAlertDialogProps {
  /**
   * The children may be:
   * - Both a Trigger and Content
   * - Or, if you are managing your modal state yourself, just Content
   */
  children:
    | [React.ReactElement<typeof DDSAlertDialogTrigger>, React.ReactElement<typeof DDSAlertDialogContent>]
    | React.ReactElement<typeof DDSAlertDialogContent>;
  "data-name"?: string;
  "event-metadata"?: object;
  experiments?: string[];
  /**
   * Whether the dialog is open.
   */
  open?: boolean;
  /**
   * Whether the dialog is open by default.
   */
  defaultOpen?: boolean;
  /**
   * Callback function to handle the dialog open state change.
   */
  onOpenChange?(open: boolean): void;
}

const DDSAlertDialogRoot = AlertDialog.Root;

const DDSAlertDialogTrigger = AlertDialog.Trigger;

function DDSAlertDialog({ children, ...props }: DDSAlertDialogProps) {
  const { "data-name": dataName, "event-metadata": metadata, experiments, open } = props;

  React.useEffect(() => {
    if (!dataName) {
      logMessage("WARN", `${getSite()}.alertDialog.missingDataName`);
      return;
    }

    logEvent({
      eventName: `${getSite()}.alertDialog.${dataName}.open`,
      automatedEvent: true,
      metadata,
      experiments,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savedElement = useRef<(typeof document)["activeElement"]>();

  useLayoutEffect(() => {
    if (typeof open !== "boolean") return;
    if (open) {
      savedElement.current = document.activeElement;
    } else {
      savedElement.current &&
        "focus" in savedElement.current &&
        typeof savedElement.current.focus === "function" &&
        savedElement.current.focus();
    }
  }, [open]);

  return (
    <DDSAlertDialogRoot open={open} {...props}>
      {children}
    </DDSAlertDialogRoot>
  );
}

function DDSAlertDialogContent({ children, ...props }: AlertDialog.AlertDialogContentProps) {
  return (
    <AlertDialog.Overlay className={classes.overlay}>
      <AlertDialog.Content
        className={classes.container}
        aria-labelledby="alert-dialog-title"
        onFocusCapture={(e) => {
          // This prevents focus to the dialog from being captured when the dialog is opened.
          e.stopPropagation();
        }}
        {...props}
      >
        <div className={classes.content}>{children}</div>
      </AlertDialog.Content>
    </AlertDialog.Overlay>
  );
}

DDSAlertDialogContent.displayName = "DDSAlertDialogContent";

// Add a11y configuration to the component
DDSAlertDialogContent.parameters = {
  a11y: {
    config: {
      rules: [
        {
          // We use aria-labelledby for dialog naming, so we can disable this rule
          id: "aria-dialog-name",
          enabled: false,
        },
      ],
    },
  },
};

function DDSAlertDialogHeader({ children }: { children: ReactNode }) {
  return <div className={classes.header}>{children}</div>;
}

function DDSAlertDialogFooter({ children }: { children: ReactNode }) {
  return <div className={classes.footer}>{children}</div>;
}

function DDSAlertDialogTitle({ children }: { children: ReactNode }) {
  return (
    <AlertDialog.Title asChild id="alert-dialog-title">
      <DDSHeadlineText level={3} as="h3" className={classes.title}>
        {children}
      </DDSHeadlineText>
    </AlertDialog.Title>
  );
}

function DDSAlertDialogDescription({ children }: { children: ReactNode }) {
  return (
    <AlertDialog.Description asChild>
      <div className={classes.description}>
        <DDSBodyText level={2}>{children}</DDSBodyText>
      </div>
    </AlertDialog.Description>
  );
}

type DDSActionProps = {
  children: ReactNode;
  ["data-name"]: string;
  variant?: "primary" | "destructive";
  disabled?: boolean;
  onClick?: () => void;
};

function DDSAlertDialogAction({
  children,
  variant = "primary",
  disabled,
  onClick,
  "data-name": dataName,
}: DDSActionProps & { "data-name": string }) {
  return (
    <AlertDialog.Action asChild>
      <DDSButton data-name={dataName} variant={variant} size="medium" disabled={disabled} onClick={onClick}>
        {children}
      </DDSButton>
    </AlertDialog.Action>
  );
}

function DDSAlertDialogCancel({
  children,
  "data-name": dataName,
  onClick,
}: {
  children: ReactNode;
  "data-name": string;
  onClick?: () => void;
}) {
  return (
    <AlertDialog.Cancel asChild>
      <DDSButton data-name={dataName} variant="secondary" size="medium" onClick={onClick}>
        {children}
      </DDSButton>
    </AlertDialog.Cancel>
  );
}

export {
  DDSAlertDialog,
  DDSAlertDialogTrigger,
  DDSAlertDialogContent,
  DDSAlertDialogHeader,
  DDSAlertDialogFooter,
  DDSAlertDialogTitle,
  DDSAlertDialogDescription,
  DDSAlertDialogAction,
  DDSAlertDialogCancel,
};
