import classes from "./avatar.module.css";

export const Sizes = ["xxs", "xs", "s", "m", "l", "auto"] as const;
export type EntityInfo = {
  firstName?: string;
  lastName?: string;
  entityId?: string;
  avatarURL?: string;
};

type AvatarSource = { type: "url"; value: string } | { type: "initials"; value: string; color: string } | null;

export type Props = {
  size: (typeof Sizes)[number];
  entityInfo?: EntityInfo;
};

export function DDSAvatar({ size = "m", entityInfo }: Props) {
  const source = toSource(entityInfo);
  const className = [classes[size]];

  if (source) {
    if (source.type === "initials") {
      className.push(source.color);
      className.push(classes.initials);

      return (
        <div
          role="img"
          aria-hidden={true}
          className={className.join(" ")}
          data-entity-info={JSON.stringify(entityInfo)}
        >
          {source.value}
        </div>
      );
    }

    if (source.type === "url") {
      className.push(classes.image);
      return (
        <div
          role="img"
          aria-hidden={true}
          data-entity-info={JSON.stringify(entityInfo)}
          className={className.join(" ")}
          style={{ backgroundImage: `url(${source.value})` }}
        />
      );
    }
  }
  return (
    <img
      src="https://static.classdojo.com/missing_user_avatar.png"
      alt=""
      className={[...className, classes.image].join(" ")}
    />
  );
}

function toSource(entity: EntityInfo | undefined): AvatarSource {
  if (!entity) {
    return null;
  }

  if (!entity.firstName && !entity.lastName && !entity.avatarURL) {
    return null;
  }

  const firstChar = entity.firstName?.[0]?.toUpperCase() ?? "";
  const lastChar = entity.lastName?.[0]?.toUpperCase() ?? "";
  const initials = `${firstChar}${lastChar}`;
  const url = entity.avatarURL ? cleanSrc(entity.avatarURL) : null;

  const r =
    url && (isCustomAvatar(entity.avatarURL) || initials.length === 0)
      ? ({ type: "url", value: url } as const)
      : ({
          type: "initials",
          value: initials,
          color: getAvatarBackgroundColorFromEntityId(entity.entityId || "abcd1234"),
        } as const);

  return r;
}

const DEFAULT_AVATAR_REGEX = /\/dojo_bootstrap\/teacher-avatars\/big\//;

function isCustomAvatar(url?: string): url is string {
  return !!url && !DEFAULT_AVATAR_REGEX.test(url);
}

const backgroundColors = [
  classes.bgOcean,
  classes.bgSky,
  classes.bgGrape,
  classes.bgFrog,
  classes.bgSun,
  classes.bgPig,
];

function getAvatarBackgroundColorFromEntityId(id: string) {
  const index = parseInt(id.slice(-4), 16) % 6;
  return backgroundColors[index];
}

function cleanSrc(src: string, relativePath?: boolean): string {
  // Fix to get us off of imgix, which costs a lot of money.
  src = src
    .replace("dojofp.imgix.net", "images.classdojo.com/filepicker")
    .replace("dojoprofilephotos.imgix.net", "images.classdojo.com/dojoprofilephotos");

  // Make sure all images have a prefix
  const userGenerated = !src.match(/[a-f0-9]{32}.png/);
  if (src[0] === "/" && src[1] !== "/" && !relativePath && userGenerated) {
    src = `https://images.classdojo.com${src}`;
  }

  return src;
}
